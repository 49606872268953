import React from "react"
import styled from "styled-components"

import Chevron from "../../images/svg/chevron.svg"

const ProductCard = ({ text, src, gatsbyImg }) => {
  return (
    <Card>
      <h3>
        {text} <Chevron />
      </h3>
      <img src={src} alt={text} draggable={false} />
    </Card>
  )
}

export default ProductCard

const Card = styled.div`
  display: inline-block;
  margin: 20px;
  height: 345px;
  width: 400px;
  position: relative;
  transition: all 0.3s;
  :hover {
    transform: translateY(-5px);
    svg {
      transform: translateX(5px);
    }
  }
  h3 {
    background-color: #ffffff;
    border-radius: 0 25px 0 25px;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40%;
  }
  img {
    border-radius: 25px;
    width: 100%;
    height: 100%;
  }
  svg {
    fill: #4881d7;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    transition: all 0.3s;
  }
  @media screen and (max-width: 500px) {
    height: auto;
    width: auto;
  }
`
