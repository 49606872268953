import React from "react"
import styled from "styled-components"

const Grid = props => <Gallery>{props.children}</Gallery>

export default Grid

const Gallery = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`
